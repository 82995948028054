import React, { useState } from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function AlertAllDismissibleIconActionsPreview(props) {
  const [dismiss1, setDismiss1] = useState(false)
  const [dismiss2, setDismiss2] = useState(false)
  const [dismiss3, setDismiss3] = useState(false)
  const [dismiss4, setDismiss4] = useState(false)
  const [dismiss5, setDismiss5] = useState(false)

  const {
    AlertDismissibleIconActionsSuccess,
    AlertDismissibleIconActionsInfo,
    AlertDismissibleIconActionsWarning,
    AlertDismissibleIconActionsDanger,
    AlertDismissibleIconActionsToast,
  } = props

  return (
    <>
      <div className="flex flex-col max-w-3xl gap-10">
        <CopyComponent
          copyToClipboardCode={AlertDismissibleIconActionsSuccess}
          componentName="AlertDismissibleIconActionsSuccess"
          disableColorPicker
        >
          {/*  <!-- Component: Dismissible Success Alert With Icon and Action Buttons --> */}
          <div
            className={`${
              dismiss1 && "hidden "
            } flex  w-full flex-col rounded border border-wuiEmerald-100 bg-wuiEmerald-50 px-4 py-3 text-sm text-wuiEmerald-500`}
            role="alert"
          >
            {/*    <!-- Alert heading with icon & close button --> */}
            <div className="mb-2 flex items-center gap-4">
              {/*      <!-- Icon --> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 shrink-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                role="graphics-symbol"
                aria-labelledby="title-21 desc-21"
              >
                <title id="title-21">Icon title</title>
                <desc id="desc-21">
                  A more detailed description of the icon
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {/*      <!-- Headline --> */}
              <h3 className="flex-1 font-semibold ">
                All components are now published.
              </h3>
              {/*      <!-- Close button --> */}
              <button
                aria-label="Close"
                className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-wuiEmerald-500 transition duration-300 hover:bg-wuiEmerald-100 hover:text-wuiEmerald-600 focus:bg-wuiEmerald-200 focus:text-wuiEmerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiEmerald-300 disabled:shadow-none disabled:hover:bg-transparent"
                onClick={() => setDismiss1(true)}
              >
                <span className="relative only:-mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    role="graphics-symbol"
                    aria-labelledby="title-22 desc-22"
                  >
                    <title id="title-22">Icon title</title>
                    <desc id="desc-22">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </button>
            </div>
            {/*    <!-- Alert body --> */}
            <div className="px-9">
              <p>
                You successfully read this important alert message. Green often
                indicates something successful or positive.
              </p>
            </div>
            {/*    <!-- Alert actions --> */}
            <div className="mt-4 flex gap-2 px-9">
              <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-wuiEmerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-wuiEmerald-600 focus:bg-wuiEmerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-wuiEmerald-300 disabled:bg-wuiEmerald-300 disabled:shadow-none">
                <span>proceed</span>
              </button>
              <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-4 text-xs font-medium tracking-wide text-wuiEmerald-500 transition duration-300 hover:bg-wuiEmerald-100 hover:text-wuiEmerald-600 focus:bg-wuiEmerald-200 focus:text-wuiEmerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiEmerald-300 disabled:shadow-none disabled:hover:bg-transparent">
                <span className="relative">Cancel</span>
              </button>
            </div>
          </div>
          {/*  <!-- End Dismissible Success Alert With Icon and Action Buttons --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertDismissibleIconActionsWarning}
          componentName="AlertDismissibleIconActionsWarning"
          disableColorPicker
        >
          {/*  <!-- Component: Dismissible Warning Alert With Icon and Action Buttons --> */}
          <div
            className={`${
              dismiss2 && "hidden "
            }  flex  w-full flex-col rounded border border-wuiAmber-100 bg-wuiAmber-50 px-4 py-3 text-sm text-wuiAmber-500`}
            role="alert"
          >
            {/*    <!-- Alert heading with icon & close button --> */}
            <div className="mb-2 flex items-center gap-4">
              {/*      <!-- Icon --> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 shrink-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                role="graphics-symbol"
                aria-labelledby="title-23 desc-23"
              >
                <title id="title-23">Icon title</title>
                <desc id="desc-23">
                  A more detailed description of the icon
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              {/*      <!-- Headline --> */}
              <h3 className="flex-1 font-semibold">
                Some components are not published!
              </h3>
              {/*      <!-- Close button --> */}
              <button
                aria-label="Close"
                className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-wuiAmber-500 transition duration-300 hover:bg-wuiAmber-100 hover:text-wuiAmber-600 focus:bg-wuiAmber-200 focus:text-wuiAmber-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiAmber-300 disabled:shadow-none disabled:hover:bg-transparent"
                onClick={() => setDismiss2(true)}
              >
                <span className="relative only:-mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    role="graphics-symbol"
                    aria-labelledby="title-24 desc-24"
                  >
                    <title id="title-24">Icon title</title>
                    <desc id="desc-24">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </button>
            </div>
            {/*    <!-- Alert body --> */}
            <div className="px-9">
              <p>
                You successfully read this important alert message. Yellow often
                indicates a warning that might need attention.
              </p>
            </div>
            {/*    <!-- Alert actions --> */}
            <div className="mt-4 flex gap-2 px-9">
              <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-wuiAmber-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-wuiAmber-600 focus:bg-wuiAmber-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-wuiAmber-300 disabled:bg-wuiAmber-300 disabled:shadow-none">
                <span>proceed</span>
              </button>
              <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-4 text-xs font-medium tracking-wide text-wuiAmber-500 transition duration-300 hover:bg-wuiAmber-100 hover:text-wuiAmber-600 focus:bg-wuiAmber-200 focus:text-wuiAmber-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiAmber-300 disabled:shadow-none disabled:hover:bg-transparent">
                <span className="relative">Cancel</span>
              </button>
            </div>
          </div>
          {/*  <!-- End Dismissible Warning Alert With Icon and Action Buttons --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertDismissibleIconActionsInfo}
          componentName="AlertDismissibleIconActionsInfo"
          disableColorPicker
        >
          {/*  <!-- Component: Dismissible Info Alert With Icon and Action Buttons --> */}
          <div
            className={`${
              dismiss3 && "hidden "
            } flex  w-full flex-col rounded border border-wuiCyan-100 bg-wuiCyan-50 px-4 py-3 text-sm text-wuiCyan-500`}
            role="alert"
          >
            {/*    <!-- Alert heading with icon & close button --> */}
            <div className="mb-2 flex items-center gap-4">
              {/*      <!-- Icon --> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 shrink-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                role="graphics-symbol"
                aria-labelledby="title-25 desc-25"
              >
                <title id="title-25">Icon title</title>
                <desc id="desc-25">
                  A more detailed description of the icon
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                />
              </svg>
              {/*      <!-- Headline --> */}
              <h3 className="flex-1 font-semibold">
                All components are now live.
              </h3>
              {/*      <!-- Close button --> */}
              <button
                aria-label="Close"
                className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-wuiCyan-500 transition duration-300 hover:bg-wuiCyan-100 hover:text-wuiCyan-600 focus:bg-wuiCyan-200 focus:text-wuiCyan-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiCyan-300 disabled:shadow-none disabled:hover:bg-transparent"
                onClick={() => setDismiss3(true)}
              >
                <span className="relative only:-mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    role="graphics-symbol"
                    aria-labelledby="title-26 desc-26"
                  >
                    <title id="title-26">Icon title</title>
                    <desc id="desc-26">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </button>
            </div>
            {/*    <!-- Alert body --> */}
            <div className="px-9">
              <p>
                You successfully read this important alert message. Blue often
                indicates a neutral informative change or action.
              </p>
            </div>
            {/*    <!-- Alert actions --> */}
            <div className="mt-4 flex gap-2 px-9">
              <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-wuiCyan-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-wuiCyan-600 focus:bg-wuiCyan-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-wuiCyan-300 disabled:bg-wuiCyan-300 disabled:shadow-none">
                <span>proceed</span>
              </button>
              <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-4 text-xs font-medium tracking-wide text-wuiCyan-500 transition duration-300 hover:bg-wuiCyan-100 hover:text-wuiCyan-600 focus:bg-wuiCyan-200 focus:text-wuiCyan-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiCyan-300 disabled:shadow-none disabled:hover:bg-transparent">
                <span className="relative">Cancel</span>
              </button>
            </div>
          </div>
          {/*  <!-- End Dismissible Info Alert With Icon and Action Buttons --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertDismissibleIconActionsDanger}
          componentName="AlertDismissibleIconActionsDanger"
          disableColorPicker
        >
          {/*  <!-- Component: Dismissible Danger Alert With Icon and Action Buttons --> */}
          <div
            className={`${
              dismiss4 && "hidden "
            } flex  w-full flex-col rounded border border-wuiPink-100 bg-wuiPink-50 px-4 py-3 text-sm text-wuiPink-500`}
            role="alert"
          >
            {/*    <!-- Alert heading with icon & close button --> */}
            <div className="mb-2 flex items-center gap-4">
              {/*      <!-- Icon --> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 shrink-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                role="graphics-symbol"
                aria-labelledby="title-27 desc-27"
              >
                <title id="title-27">Icon title</title>
                <desc id="desc-27">
                  A more detailed description of the icon
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {/*      <!-- Headline --> */}
              <h3 className="flex-1 font-semibold">
                Uploading components failed!
              </h3>
              {/*      <!-- Close button --> */}
              <button
                aria-label="Close"
                className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-wuiPink-500 transition duration-300 hover:bg-wuiPink-100 hover:text-wuiPink-600 focus:bg-wuiPink-200 focus:text-wuiPink-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiPink-300 disabled:shadow-none disabled:hover:bg-transparent"
                onClick={() => setDismiss4(true)}
              >
                <span className="relative only:-mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    role="graphics-symbol"
                    aria-labelledby="title-28 desc-28"
                  >
                    <title id="title-28">Icon title</title>
                    <desc id="desc-28">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </button>
            </div>
            {/*    <!-- Alert body --> */}
            <div className="px-9">
              <p>
                You successfully read this important alert message. Red often
                indicates a dangerous or negative situation.
              </p>
            </div>
            {/*    <!-- Alert actions --> */}
            <div className="mt-4 flex gap-2 px-9">
              <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-wuiPink-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-wuiPink-600 focus:bg-wuiPink-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-wuiPink-300 disabled:bg-wuiPink-300 disabled:shadow-none">
                <span>proceed</span>
              </button>
              <button
                aria-label=" Close"
                className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-4 text-xs font-medium tracking-wide text-wuiPink-500 transition duration-300 hover:bg-wuiPink-100 hover:text-wuiPink-600 focus:bg-wuiPink-200 focus:text-wuiPink-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiPink-300 disabled:shadow-none disabled:hover:bg-transparent"
              >
                <span className="relative">Cancel</span>
              </button>
            </div>
          </div>
          {/*  <!-- End Dismissible Danger Alert With Icon and Action Buttons --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertDismissibleIconActionsToast}
          componentName="AlertDismissibleIconActionsToast"
          disableColorPicker
        >
          {/*  <!-- Component: Dismissible Toast Alert With Icon and Action Buttons --> */}
          <div
            className={`${
              dismiss5 && "hidden "
            }  flex  w-full flex-col rounded border border-wuiSlate-900 bg-wuiSlate-700 px-4 py-3 text-sm text-wuiSlate-200`}
            role="alert"
          >
            {/*    <!-- Alert heading with icon & close button --> */}
            <div className="mb-2 flex items-center gap-4">
              {/*      <!-- Icon --> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 shrink-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                role="graphics-symbol"
                aria-labelledby="title-29 desc-29"
              >
                <title id="title-29">Icon title</title>
                <desc id="desc-29">
                  A more detailed description of the icon
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
              {/*      <!-- Headline --> */}
              <h3 className="flex-1 font-semibold">
                Uploading failed, retrying in 5 sec
              </h3>
              {/*      <!-- Close button --> */}
              <button
                aria-label="Close"
                className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-wuiSlate-200 transition duration-300 hover:bg-wuiSlate-600 hover:text-wuiSlate-100 focus:bg-wuiSlate-500 focus:text-wuiSlate-100 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiSlate-600 disabled:shadow-none disabled:hover:bg-transparent"
                onClick={() => setDismiss5(true)}
              >
                <span className="relative only:-mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    role="graphics-symbol"
                    aria-labelledby="title-30 desc-30"
                  >
                    <title id="title-30">Icon title</title>
                    <desc id="desc-30">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </button>
            </div>
            {/*    <!-- Alert body --> */}
            <div className="px-9">
              <p>
                You successfully read this important alert message. Grey
                represents neutrality and balance.
              </p>
            </div>
            {/*    <!-- Alert actions --> */}
            <div className="mt-4 flex gap-2 px-9">
              <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-wuiSlate-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-wuiSlate-600 focus:bg-wuiSlate-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-wuiSlate-300 disabled:bg-wuiSlate-300 disabled:shadow-none">
                <span>proceed</span>
              </button>
              <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-4 text-xs font-medium tracking-wide text-wuiSlate-200 transition duration-300 hover:bg-wuiSlate-600 hover:text-wuiSlate-100 focus:bg-wuiSlate-500 focus:text-wuiSlate-100 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiSlate-600 disabled:shadow-none disabled:hover:bg-transparent">
                <span className="relative">Logout</span>
              </button>
            </div>
          </div>
          {/*  <!-- End Dismissible Toast Alert With Icon and Action Buttons --> */}
        </CopyComponent>
      </div>
    </>
  )
}
