import React, { useState } from "react"
import { Link } from "gatsby"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ogImage from "../../../images/seo/alerts/alerts.png"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
import AlertSimplePreview from "../../../library/components/alert/react/_preview/simple"
import AlertTitleTextPreview from "../../../library/components/alert/react/_preview/titleText"
import AlertIconsPreview from "../../../library/components/alert/react/_preview/icons"
import AlertIconsTitleTextPreview from "../../../library/components/alert/react/_preview/iconsTitleText"
import AlertDismissiblePreview from "../../../library/components/alert/react/_preview/dismissible"
import AlertDismissibleActionsPreview from "../../../library/components/alert/react/_preview/dismissibleActions"
import AlertDismissibleIconActionsPreview from "../../../library/components/alert/react/_preview/dismissibleIconActions"

import AlertHeroComponent from "../../../library/components/alert/react/_preview/AlertHeroComponent"

// Simple
const AlertSimpleSuccessJsx = require("!!raw-loader!../../../library/components/alert/react/simple/success.jsx")
const AlertSimpleSuccessHTML = require("!!raw-loader!../../../library/components/alert/html/simple/success.html")

const AlertSimpleInfoJsx = require("!!raw-loader!../../../library/components/alert/react/simple/info.jsx")
const AlertSimpleInfoHTML = require("!!raw-loader!../../../library/components/alert/html/simple/info.html")

const AlertSimpleWarningJsx = require("!!raw-loader!../../../library/components/alert/react/simple/warning.jsx")
const AlertSimpleWarningHTML = require("!!raw-loader!../../../library/components/alert/html/simple/warning.html")

const AlertSimpleDangerJsx = require("!!raw-loader!../../../library/components/alert/react/simple/danger.jsx")
const AlertSimpleDangerHTML = require("!!raw-loader!../../../library/components/alert/html/simple/danger.html")

const AlertSimpleToastJsx = require("!!raw-loader!../../../library/components/alert/react/simple/toast.jsx")
const AlertSimpleToastHTML = require("!!raw-loader!../../../library/components/alert/html/simple/toast.html")

// Title Text
const AlertTitleTextSuccessJsx = require("!!raw-loader!../../../library/components/alert/react/titleText/success.jsx")
const AlertTitleTextSuccessHTML = require("!!raw-loader!../../../library/components/alert/html/titleText/success.html")

const AlertTitleTextInfoJsx = require("!!raw-loader!../../../library/components/alert/react/titleText/info.jsx")
const AlertTitleTextInfoHTML = require("!!raw-loader!../../../library/components/alert/html/titleText/info.html")

const AlertTitleTextWarningJsx = require("!!raw-loader!../../../library/components/alert/react/titleText/warning.jsx")
const AlertTitleTextWarningHTML = require("!!raw-loader!../../../library/components/alert/html/titleText/warning.html")

const AlertTitleTextDangerJsx = require("!!raw-loader!../../../library/components/alert/react/titleText/danger.jsx")
const AlertTitleTextDangerHTML = require("!!raw-loader!../../../library/components/alert/html/titleText/danger.html")

const AlertTitleTextToastJsx = require("!!raw-loader!../../../library/components/alert/react/titleText/toast.jsx")
const AlertTitleTextToastHTML = require("!!raw-loader!../../../library/components/alert/html/titleText/toast.html")

// Icons
const AlertIconsTextSuccessJsx = require("!!raw-loader!../../../library/components/alert/react/icons/success.jsx")
const AlertIconsTextSuccessHTML = require("!!raw-loader!../../../library/components/alert/html/icons/success.html")

const AlertIconsTextInfoJsx = require("!!raw-loader!../../../library/components/alert/react/icons/info.jsx")
const AlertIconsTextInfoHTML = require("!!raw-loader!../../../library/components/alert/html/icons/info.html")

const AlertIconsTextWarningJsx = require("!!raw-loader!../../../library/components/alert/react/icons/warning.jsx")
const AlertIconsTextWarningHTML = require("!!raw-loader!../../../library/components/alert/html/icons/warning.html")

const AlertIconsTextDangerJsx = require("!!raw-loader!../../../library/components/alert/react/icons/danger.jsx")
const AlertIconsTextDangerHTML = require("!!raw-loader!../../../library/components/alert/html/icons/danger.html")

const AlertIconsTextToastJsx = require("!!raw-loader!../../../library/components/alert/react/icons/toast.jsx")
const AlertIconsTextToastHTML = require("!!raw-loader!../../../library/components/alert/html/icons/toast.html")

// Icons Title Text
const AlertIconsTitleTextSuccessJsx = require("!!raw-loader!../../../library/components/alert/react/iconsTitleText/success.jsx")
const AlertIconsTitleTextSuccessHTML = require("!!raw-loader!../../../library/components/alert/html/iconsTitleText/success.html")

const AlertIconsTitleTextInfoJsx = require("!!raw-loader!../../../library/components/alert/react/iconsTitleText/info.jsx")
const AlertIconsTitleTextInfoHTML = require("!!raw-loader!../../../library/components/alert/html/iconsTitleText/info.html")

const AlertIconsTitleTextWarningJsx = require("!!raw-loader!../../../library/components/alert/react/iconsTitleText/warning.jsx")
const AlertIconsTitleTextWarningHTML = require("!!raw-loader!../../../library/components/alert/html/iconsTitleText/warning.html")

const AlertIconsTitleTextDangerJsx = require("!!raw-loader!../../../library/components/alert/react/iconsTitleText/danger.jsx")
const AlertIconsTitleTextDangerHTML = require("!!raw-loader!../../../library/components/alert/html/iconsTitleText/danger.html")

const AlertIconsTitleTextToastJsx = require("!!raw-loader!../../../library/components/alert/react/iconsTitleText/toast.jsx")
const AlertIconsTitleTextToastHTML = require("!!raw-loader!../../../library/components/alert/html/iconsTitleText/toast.html")

// Dismissible
const AlertDismissibleSuccessJsx = require("!!raw-loader!../../../library/components/alert/react/dismissible/success.jsx")
const AlertDismissibleSuccessHTML = require("!!raw-loader!../../../library/components/alert/html/dismissible/success.html")

const AlertDismissibleInfoJsx = require("!!raw-loader!../../../library/components/alert/react/dismissible/info.jsx")
const AlertDismissibleInfoHTML = require("!!raw-loader!../../../library/components/alert/html/dismissible/info.html")

const AlertDismissibleWarningJsx = require("!!raw-loader!../../../library/components/alert/react/dismissible/warning.jsx")
const AlertDismissibleWarningHTML = require("!!raw-loader!../../../library/components/alert/html/dismissible/warning.html")

const AlertDismissibleDangerJsx = require("!!raw-loader!../../../library/components/alert/react/dismissible/danger.jsx")
const AlertDismissibleDangerHTML = require("!!raw-loader!../../../library/components/alert/html/dismissible/danger.html")

const AlertDismissibleToastJsx = require("!!raw-loader!../../../library/components/alert/react/dismissible/toast.jsx")
const AlertDismissibleToastHTML = require("!!raw-loader!../../../library/components/alert/html/dismissible/toast.html")

// Dismissible Actions
const AlertDismissibleActionsSuccessJsx = require("!!raw-loader!../../../library/components/alert/react/dismissibleActions/success.jsx")
const AlertDismissibleActionsSuccessHTML = require("!!raw-loader!../../../library/components/alert/html/dismissibleActions/success.html")

const AlertDismissibleActionsInfoJsx = require("!!raw-loader!../../../library/components/alert/react/dismissibleActions/info.jsx")
const AlertDismissibleActionsInfoHTML = require("!!raw-loader!../../../library/components/alert/html/dismissibleActions/info.html")

const AlertDismissibleActionsWarningJsx = require("!!raw-loader!../../../library/components/alert/react/dismissibleActions/warning.jsx")
const AlertDismissibleActionsWarningHTML = require("!!raw-loader!../../../library/components/alert/html/dismissibleActions/warning.html")

const AlertDismissibleActionsDangerJsx = require("!!raw-loader!../../../library/components/alert/react/dismissibleActions/danger.jsx")
const AlertDismissibleActionsDangerHTML = require("!!raw-loader!../../../library/components/alert/html/dismissibleActions/danger.html")

const AlertDismissibleActionsToastJsx = require("!!raw-loader!../../../library/components/alert/react/dismissibleActions/toast.jsx")
const AlertDismissibleActionsToastHTML = require("!!raw-loader!../../../library/components/alert/html/dismissibleActions/toast.html")

// Dismissible Icon Actions
const AlertDismissibleIconActionsSuccessJsx = require("!!raw-loader!../../../library/components/alert/react/dismissibleIconActions/success.jsx")
const AlertDismissibleIconActionsSuccessHTML = require("!!raw-loader!../../../library/components/alert/html/dismissibleIconActions/success.html")

const AlertDismissibleIconActionsInfoJsx = require("!!raw-loader!../../../library/components/alert/react/dismissibleIconActions/info.jsx")
const AlertDismissibleIconActionsInfoHTML = require("!!raw-loader!../../../library/components/alert/html/dismissibleIconActions/info.html")

const AlertDismissibleIconActionsWarningJsx = require("!!raw-loader!../../../library/components/alert/react/dismissibleIconActions/warning.jsx")
const AlertDismissibleIconActionsWarningHTML = require("!!raw-loader!../../../library/components/alert/html/dismissibleIconActions/warning.html")

const AlertDismissibleIconActionsDangerJsx = require("!!raw-loader!../../../library/components/alert/react/dismissibleIconActions/danger.jsx")
const AlertDismissibleIconActionsDangerHTML = require("!!raw-loader!../../../library/components/alert/html/dismissibleIconActions/danger.html")

const AlertDismissibleIconActionsToastJsx = require("!!raw-loader!../../../library/components/alert/react/dismissibleIconActions/toast.jsx")
const AlertDismissibleIconActionsToastHTML = require("!!raw-loader!../../../library/components/alert/html/dismissibleIconActions/toast.html")

export default function AlertsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "AlertSimple",
      title: "Simple alerts",
      active_tab: 1,
    },
    {
      component_name: "AlertTitleText",
      title: "With title and supportive text",
      active_tab: 1,
    },
    {
      component_name: "AlertIcons",
      title: "With icons",
      active_tab: 1,
    },
    {
      component_name: "AlertIconsTitleText",
      title: "Icons, title and supportive text",
      active_tab: 1,
    },
    {
      component_name: "AlertDismissible",
      title: "Dismissible alerts",
      active_tab: 1,
    },

    {
      component_name: "AlertDismissibleActions",
      title: "Dismissible with action button",
      active_tab: 1,
    },
    {
      component_name: "AlertDismissibleIconActions",
      title: "Dismissible with title, icon and actions",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Alerts - WindUI Component Library"
        ogtitle="Tailwind CSS Alerts - WindUI Component Library"
        description="An alert is an element that displays brief, important messages, that attracts the user's attention without interrupting them. Built with Tailwind CSS by WindUI."
        ogdescription="An alert is an element that displays brief, important messages, that attracts the user's attention without interrupting them. Built with Tailwind CSS by WindUI."
        url="components/alerts/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Alerts, Alerts, Alert Component, WindUI, windui, wind ui"
        ogimage={ogImage}
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}

        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Alerts</h1>
          <p>
            An alert is an element that displays a brief, important message in a
            way that attracts the user's attention without interrupting the
            user's task.
          </p>

          {/* Hero Preview Section */}
          <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16 [&>*]:w-full">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <AlertHeroComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="AlertSimple">Simple alerts</h3>

          <PreviewBlock
            id="AlertSimple"
            HtmlComponent={AlertSimpleSuccessHTML.default}
            JsxComponent={AlertSimpleSuccessJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <AlertSimplePreview
                AlertSimpleSuccess={
                  activeTabs[0].active_tab === 1
                    ? AlertSimpleSuccessHTML.default
                    : AlertSimpleSuccessJsx.default
                }
                AlertSimpleInfo={
                  activeTabs[0].active_tab === 1
                    ? AlertSimpleInfoHTML.default
                    : AlertSimpleInfoJsx.default
                }
                AlertSimpleWarning={
                  activeTabs[0].active_tab === 1
                    ? AlertSimpleWarningHTML.default
                    : AlertSimpleWarningJsx.default
                }
                AlertSimpleDanger={
                  activeTabs[0].active_tab === 1
                    ? AlertSimpleDangerHTML.default
                    : AlertSimpleDangerJsx.default
                }
                AlertSimpleToast={
                  activeTabs[0].active_tab === 1
                    ? AlertSimpleToastHTML.default
                    : AlertSimpleToastJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AlertTitleText">Alerts with title and supportive text</h3>

          <PreviewBlock
            id="AlertTitleText"
            HtmlComponent={AlertTitleTextSuccessHTML.default}
            JsxComponent={AlertTitleTextSuccessJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <AlertTitleTextPreview
                AlertTitleTextSuccess={
                  activeTabs[1].active_tab === 1
                    ? AlertTitleTextSuccessHTML.default
                    : AlertTitleTextSuccessJsx.default
                }
                AlertTitleTextInfo={
                  activeTabs[1].active_tab === 1
                    ? AlertTitleTextInfoHTML.default
                    : AlertTitleTextInfoJsx.default
                }
                AlertTitleTextWarning={
                  activeTabs[1].active_tab === 1
                    ? AlertTitleTextWarningHTML.default
                    : AlertTitleTextWarningJsx.default
                }
                AlertTitleTextDanger={
                  activeTabs[1].active_tab === 1
                    ? AlertTitleTextDangerHTML.default
                    : AlertTitleTextDangerJsx.default
                }
                AlertTitleTextToast={
                  activeTabs[1].active_tab === 1
                    ? AlertTitleTextToastHTML.default
                    : AlertTitleTextToastJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AlertIcons">Alerts with icons</h3>

          <PreviewBlock
            id="AlertIcons"
            HtmlComponent={AlertIconsTextSuccessHTML.default}
            JsxComponent={AlertIconsTextSuccessJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <AlertIconsPreview
                AlertIconsTextSuccess={
                  activeTabs[2].active_tab === 1
                    ? AlertIconsTextSuccessHTML.default
                    : AlertIconsTextSuccessJsx.default
                }
                AlertIconsTextInfo={
                  activeTabs[2].active_tab === 1
                    ? AlertIconsTextInfoHTML.default
                    : AlertIconsTextInfoJsx.default
                }
                AlertIconsTextWarning={
                  activeTabs[2].active_tab === 1
                    ? AlertIconsTextWarningHTML.default
                    : AlertIconsTextWarningJsx.default
                }
                AlertIconsTextDanger={
                  activeTabs[2].active_tab === 1
                    ? AlertIconsTextDangerHTML.default
                    : AlertIconsTextDangerJsx.default
                }
                AlertIconsTextToast={
                  activeTabs[2].active_tab === 1
                    ? AlertIconsTextToastHTML.default
                    : AlertIconsTextToastJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AlertIconsTitleText">
            Alerts with icons, title and supportive text
          </h3>

          <PreviewBlock
            id="AlertIconsTitleText"
            HtmlComponent={AlertIconsTitleTextSuccessHTML.default}
            JsxComponent={AlertIconsTitleTextSuccessJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <AlertIconsTitleTextPreview
                AlertIconsTitleTextSuccess={
                  activeTabs[3].active_tab === 1
                    ? AlertIconsTitleTextSuccessHTML.default
                    : AlertIconsTitleTextSuccessJsx.default
                }
                AlertIconsTitleTextInfo={
                  activeTabs[3].active_tab === 1
                    ? AlertIconsTitleTextInfoHTML.default
                    : AlertIconsTitleTextInfoJsx.default
                }
                AlertIconsTitleTextWarning={
                  activeTabs[3].active_tab === 1
                    ? AlertIconsTitleTextWarningHTML.default
                    : AlertIconsTitleTextWarningJsx.default
                }
                AlertIconsTitleTextDanger={
                  activeTabs[3].active_tab === 1
                    ? AlertIconsTitleTextDangerHTML.default
                    : AlertIconsTitleTextDangerJsx.default
                }
                AlertIconsTitleTextToast={
                  activeTabs[3].active_tab === 1
                    ? AlertIconsTitleTextToastHTML.default
                    : AlertIconsTitleTextToastJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AlertDismissible">
            Dismissible alerts <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="AlertDismissible"
            HtmlComponent={AlertDismissibleSuccessHTML.default}
            JsxComponent={AlertDismissibleSuccessJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <AlertDismissiblePreview
                AlertDismissibleSuccess={
                  activeTabs[4].active_tab === 1
                    ? AlertDismissibleSuccessHTML.default
                    : AlertDismissibleSuccessJsx.default
                }
                AlertDismissibleInfo={
                  activeTabs[4].active_tab === 1
                    ? AlertDismissibleInfoHTML.default
                    : AlertDismissibleInfoJsx.default
                }
                AlertDismissibleWarning={
                  activeTabs[4].active_tab === 1
                    ? AlertDismissibleWarningHTML.default
                    : AlertDismissibleWarningJsx.default
                }
                AlertDismissibleDanger={
                  activeTabs[4].active_tab === 1
                    ? AlertDismissibleDangerHTML.default
                    : AlertDismissibleDangerJsx.default
                }
                AlertDismissibleToast={
                  activeTabs[4].active_tab === 1
                    ? AlertDismissibleToastHTML.default
                    : AlertDismissibleToastJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AlertDismissibleActions">
            Dismissible alerts with action button{" "}
            <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="AlertDismissibleActions"
            HtmlComponent={AlertDismissibleActionsSuccessHTML.default}
            JsxComponent={AlertDismissibleActionsSuccessJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <AlertDismissibleActionsPreview
                AlertDismissibleActionsSuccess={
                  activeTabs[5].active_tab === 1
                    ? AlertDismissibleActionsSuccessHTML.default
                    : AlertDismissibleActionsSuccessJsx.default
                }
                AlertDismissibleActionsInfo={
                  activeTabs[5].active_tab === 1
                    ? AlertDismissibleActionsInfoHTML.default
                    : AlertDismissibleActionsInfoJsx.default
                }
                AlertDismissibleActionsWarning={
                  activeTabs[5].active_tab === 1
                    ? AlertDismissibleActionsWarningHTML.default
                    : AlertDismissibleActionsWarningJsx.default
                }
                AlertDismissibleActionsDanger={
                  activeTabs[5].active_tab === 1
                    ? AlertDismissibleActionsDangerHTML.default
                    : AlertDismissibleActionsDangerJsx.default
                }
                AlertDismissibleActionsToast={
                  activeTabs[5].active_tab === 1
                    ? AlertDismissibleActionsToastHTML.default
                    : AlertDismissibleActionsToastJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AlertDismissibleIconActions">
            Dismissible alerts with title, icon, text and actions{" "}
            <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="AlertDismissibleIconActions"
            HtmlComponent={AlertDismissibleIconActionsSuccessHTML.default}
            JsxComponent={AlertDismissibleIconActionsSuccessJsx.default}
            getActiveTabs={getActiveTabs}
            disableColorPicker
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <AlertDismissibleIconActionsPreview
                AlertDismissibleIconActionsSuccess={
                  activeTabs[6].active_tab === 1
                    ? AlertDismissibleIconActionsSuccessHTML.default
                    : AlertDismissibleIconActionsSuccessJsx.default
                }
                AlertDismissibleIconActionsInfo={
                  activeTabs[6].active_tab === 1
                    ? AlertDismissibleIconActionsInfoHTML.default
                    : AlertDismissibleIconActionsInfoJsx.default
                }
                AlertDismissibleIconActionsWarning={
                  activeTabs[6].active_tab === 1
                    ? AlertDismissibleIconActionsWarningHTML.default
                    : AlertDismissibleIconActionsWarningJsx.default
                }
                AlertDismissibleIconActionsDanger={
                  activeTabs[6].active_tab === 1
                    ? AlertDismissibleIconActionsDangerHTML.default
                    : AlertDismissibleIconActionsDangerJsx.default
                }
                AlertDismissibleIconActionsToast={
                  activeTabs[6].active_tab === 1
                    ? AlertDismissibleIconActionsToastHTML.default
                    : AlertDismissibleIconActionsToastJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Use an alert component to display a brief, important message in a
            way that will attract the user's attention without interrupting the
            user's task. Alerts can use colors to indicate the message's
            importance (info, warning, danger etc).
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Alerts have an internal padding of 1rem (16px) horizontally and
            0.75rem (12px) vertically. They can include the following elements:
          </p>
          <ul>
            <li>
              <strong>Title: </strong> 0.875rem (14px) font size and semibold
              weight.
            </li>
            <li>
              <strong>Supportive text: </strong> 0.875rem (14px) font size.
            </li>
            <li>
              <strong>Icon: </strong> 1.25rem (20px) width and height.
            </li>
            <li>
              <strong>Dismissible button: </strong> an icon-only button, small
              size (see <Link to="/components/buttons">buttons</Link> ).
            </li>
            <li>
              <strong>Action buttons: </strong> a text-only button, small size
              (see <Link to="/components/buttons">buttons</Link> ).
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            To make the alerts accessible a <code>role=&quot;alert&quot;</code>{" "}
            role is required to communicate the importance of the message to the
            user. When this role is added to the alert element, the browser will
            send out an accessible alert event to assistive technology products
            which can then notify the user.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
