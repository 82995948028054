import React, { useState } from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function AlertAllDismissiblePreview(props) {
  const [dismiss1, setDismiss1] = useState(false)
  const [dismiss2, setDismiss2] = useState(false)
  const [dismiss3, setDismiss3] = useState(false)
  const [dismiss4, setDismiss4] = useState(false)
  const [dismiss5, setDismiss5] = useState(false)

  const {
    AlertDismissibleSuccess,
    AlertDismissibleInfo,
    AlertDismissibleWarning,
    AlertDismissibleDanger,
    AlertDismissibleToast,
  } = props

  return (
    <>
      <div className="flex flex-col max-w-3xl gap-10">
        <CopyComponent
          copyToClipboardCode={AlertDismissibleSuccess}
          componentName="AlertDismissibleSuccess"
          disableColorPicker
        >
          {/*  <!-- Component: Dismissible Success Alert --> */}
          <div
            className={`${
              dismiss1 && "hidden "
            } flex w-full items-center gap-4 rounded border border-wuiEmerald-100 bg-wuiEmerald-50 px-4 py-3 text-sm text-wuiEmerald-500`}
            role="alert"
          >
            {/*    <!-- Text --> */}
            <p className="flex-1">Success! You have installed Tailwind CSS</p>
            {/*    <!-- Close button --> */}
            <button
              aria-label="Close"
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-wuiEmerald-500 transition duration-300 hover:bg-wuiEmerald-100 hover:text-wuiEmerald-600 focus:bg-wuiEmerald-200 focus:text-wuiEmerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiEmerald-300 disabled:shadow-none disabled:hover:bg-transparent"
              onClick={() => setDismiss1(true)}
            >
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-11 desc-11"
                >
                  <title id="title-11">Icon title</title>
                  <desc id="desc-11">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
          {/*  <!-- End Dismissible Success Alert --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertDismissibleWarning}
          componentName="AlertDismissibleWarning"
          disableColorPicker
        >
          {/*  <!-- Component: Dismissible Warning Alert --> */}
          <div
            className={`${
              dismiss2 && "hidden "
            } flex w-full items-center gap-4 rounded border border-wuiAmber-100 bg-wuiAmber-50 px-4 py-3 text-sm text-wuiAmber-500`}
            role="alert"
          >
            {/*    <!-- Text --> */}
            <p className="flex-1">
              Warning! Tailwind CSS requires latest version of PostCSS
            </p>
            {/*    <!-- Close button --> */}
            <button
              aria-label="Close"
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-wuiAmber-500 transition duration-300 hover:bg-wuiAmber-100 hover:text-wuiAmber-600 focus:bg-wuiAmber-200 focus:text-wuiAmber-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiAmber-300 disabled:shadow-none disabled:hover:bg-transparent"
              onClick={() => setDismiss2(true)}
            >
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-12 desc-12"
                >
                  <title id="title-12">Icon title</title>
                  <desc id="desc-12">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
          {/*  <!-- End Dismissible Warning Alert --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertDismissibleInfo}
          componentName="AlertDismissibleInfo"
          disableColorPicker
        >
          {/*  <!-- Component: Dismissible Info Alert --> */}
          <div
            className={`${
              dismiss3 && "hidden "
            } flex w-full items-center gap-4 rounded border border-wuiCyan-100 bg-wuiCyan-50 px-4 py-3 text-sm text-wuiCyan-500`}
            role="alert"
          >
            {/*    <!-- Text --> */}
            <p className="flex-1">Tip! Install Tailwind CSS IntelliSense </p>
            {/*    <!-- Close button --> */}
            <button
              aria-label="Close"
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-wuiCyan-500 transition duration-300 hover:bg-wuiCyan-100 hover:text-wuiCyan-600 focus:bg-wuiCyan-200 focus:text-wuiCyan-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiCyan-300 disabled:shadow-none disabled:hover:bg-transparent"
              onClick={() => setDismiss3(true)}
            >
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-13 desc-13"
                >
                  <title id="title-13">Icon title</title>
                  <desc id="desc-13">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
          {/*  <!-- End Dismissible Info Alert --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertDismissibleDanger}
          componentName="AlertDismissibleDanger"
          disableColorPicker
        >
          {/*  <!-- Component: Dismissible Danger Alert --> */}
          <div
            className={`${
              dismiss4 && "hidden "
            } flex w-full items-center gap-4 rounded border border-wuiPink-100 bg-wuiPink-50 px-4 py-3 text-sm text-wuiPink-500`}
            role="alert"
          >
            {/*    <!-- Text --> */}
            <p className="flex-1">Danger! Look behind you</p>
            {/*    <!-- Close button --> */}
            <button
              aria-label="Close"
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-wuiPink-500 transition duration-300 hover:bg-wuiPink-100 hover:text-wuiPink-600 focus:bg-wuiPink-200 focus:text-wuiPink-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiPink-300 disabled:shadow-none disabled:hover:bg-transparent"
              onClick={() => setDismiss4(true)}
            >
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-14 desc-14"
                >
                  <title id="title-14">Icon title</title>
                  <desc id="desc-14">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
          {/*  <!-- End Dismissible Danger Alert --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertDismissibleToast}
          componentName="AlertDismissibleToast"
          disableColorPicker
        >
          {/*  <!-- Component: Dismissible Toast Alert --> */}
          <div
            className={`${
              dismiss5 && "hidden "
            } flex w-full items-center gap-4 rounded border border-wuiSlate-900 bg-wuiSlate-700 px-4 py-3 text-sm text-wuiSlate-200`}
            role="alert"
          >
            {/*    <!-- Text --> */}
            <p className="flex-1">Welcome! Wind UI greets you</p>
            {/*    <!-- Close button --> */}
            <button
              aria-label="Close"
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-wuiSlate-200 transition duration-300 hover:bg-wuiSlate-600 hover:text-wuiSlate-100 focus:bg-wuiSlate-500 focus:text-wuiSlate-100 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-wuiSlate-600 disabled:shadow-none disabled:hover:bg-transparent"
              onClick={() => setDismiss5(true)}
            >
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-15 desc-15"
                >
                  <title id="title-15">Icon title</title>
                  <desc id="desc-15">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
          {/*  <!-- End Dismissible Toast Alert --> */}
        </CopyComponent>
      </div>
    </>
  )
}
