import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function AlertAllIconsPreview(props) {
  const {
    AlertIconsTextSuccess,
    AlertIconsTextInfo,
    AlertIconsTextWarning,
    AlertIconsTextDanger,
    AlertIconsTextToast,
  } = props

  return (
    <>
      <div className="flex flex-col max-w-3xl gap-10">
        <CopyComponent
          copyToClipboardCode={AlertIconsTextSuccess}
          componentName="AlertIconsTextSuccess"
          disableColorPicker
        >
          {/*  <!-- Component: Success Alert With Icon --> */}
          <div
            className="flex w-full items-start gap-4 rounded border border-wuiEmerald-100 bg-wuiEmerald-50 px-4 py-3 text-sm text-wuiEmerald-500"
            role="alert"
          >
            {/*    <!-- Icon --> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              role="graphics-symbol"
              aria-labelledby="title-01 desc-01"
            >
              <title id="title-01">Icon title</title>
              <desc id="desc-01">A more detailed description of the icon</desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {/*    <!-- Text --> */}
            <p>Success! You have installed Tailwind CSS</p>
          </div>
          {/*  <!-- End Success Alert With Icon --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertIconsTextWarning}
          componentName="AlertIconsTextWarning"
          disableColorPicker
        >
          {/*  <!-- Component: Warning Alert With Icon --> */}
          <div
            className="flex w-full items-start gap-4 rounded border border-wuiAmber-100 bg-wuiAmber-50 px-4 py-3 text-sm text-wuiAmber-500"
            role="alert"
          >
            {/*    <!-- Icon --> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              role="graphics-symbol"
              aria-labelledby="title-02 desc-02"
            >
              <title id="title-02">Icon title</title>
              <desc id="desc-02">A more detailed description of the icon</desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            {/*    <!-- Text --> */}
            <p>Warning! Tailwind CSS requires latest version of PostCSS</p>
          </div>
          {/*  <!-- End Warning Alert With Icon --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertIconsTextInfo}
          componentName="AlertIconsTextInfo"
          disableColorPicker
        >
          {/*  <!-- Component: Info Alert With Icon --> */}
          <div
            className="flex w-full items-start gap-4 rounded border border-wuiCyan-100 bg-wuiCyan-50 px-4 py-3 text-sm text-wuiCyan-500"
            role="alert"
          >
            {/*    <!-- Icon --> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              role="graphics-symbol"
              aria-labelledby="title-03 desc-03"
            >
              <title id="title-03">Icon title</title>
              <desc id="desc-03">A more detailed description of the icon</desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
              />
            </svg>
            {/*    <!-- Text --> */}
            <p>Tip! Install Tailwind CSS IntelliSense</p>
          </div>
          {/*  <!-- End Info Alert With Icon --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertIconsTextDanger}
          componentName="AlertIconsTextDanger"
          disableColorPicker
        >
          {/*  <!-- Component: Danger Alert With Icon --> */}
          <div
            className="flex w-full items-start gap-4 rounded border border-wuiPink-100 bg-wuiPink-50 px-4 py-3 text-sm text-wuiPink-500"
            role="alert"
          >
            {/*    <!-- Icon --> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              role="graphics-symbol"
              aria-labelledby="title-04 desc-04"
            >
              <title id="title-04">Icon title</title>
              <desc id="desc-04">A more detailed description of the icon</desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {/*    <!-- Text --> */}
            <p>Danger! Look behind you</p>
          </div>
          {/*  <!-- End Danger Alert With Icon --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertIconsTextToast}
          componentName="AlertIconsTextToast"
          disableColorPicker
        >
          {/*  <!-- Component: Toast Alert With Icon --> */}
          <div
            className="flex w-full items-start gap-4 rounded border border-wuiSlate-900 bg-wuiSlate-700 px-4 py-3 text-sm text-wuiSlate-200"
            role="alert"
          >
            {/*    <!-- Icon --> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              role="graphics-symbol"
              aria-labelledby="title-05 desc-05"
            >
              <title id="title-05">Icon title</title>
              <desc id="desc-05">A more detailed description of the icon</desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            {/*    <!-- Text --> */}
            <p>Welcome! Wind UI greets you</p>
          </div>
          {/*  <!-- End Toast Alert With Icon --> */}
        </CopyComponent>
      </div>
    </>
  )
}
