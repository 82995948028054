import React from "react"

function AlertHeroComponent() {
  return (
    <>
      {/*<!-- Component: Info Alert With Icon --> */}
      <div
        className="flex items-start w-full max-w-3xl gap-4 px-4 py-3 mx-auto text-sm border rounded border-emerald-100 bg-emerald-50 text-emerald-500"
        role="alert"
      >
        {/*    <!-- Icon --> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 shrink-0"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="1.5"
          role="graphics-symbol"
          aria-labelledby="title-06 desc-06"
        >
          <title id="title-06">Icon title</title>
          <desc id="desc-06">A more detailed description of the icon</desc>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        {/*    <!-- Text --> */}
        <div>
          <h3 className="mb-2 font-semibold">
            All components are now published.
          </h3>
          <p>
            You successfully read this important alert message. Green often
            indicates something successful or positive.
          </p>
        </div>
      </div>
      {/*<!-- End Info Alert With Icon --> */}
    </>
  )
}

export default AlertHeroComponent
