import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function AlertAllSimplePreview(props) {
  const {
    AlertSimpleSuccess,
    AlertSimpleInfo,
    AlertSimpleWarning,
    AlertSimpleDanger,
    AlertSimpleToast,
  } = props

  return (
    <>
      <div className="flex flex-col max-w-3xl gap-10">
        <CopyComponent
          copyToClipboardCode={AlertSimpleSuccess}
          componentName="AlertSimpleSuccess"
          disableColorPicker
        >
          {/*  <!-- Component: Simple Success Alert --> */}
          <div
            className="w-full px-4 py-3 text-sm border rounded border-wuiEmerald-100 bg-wuiEmerald-50 text-wuiEmerald-500"
            role="alert"
          >
            <p>Success! You have installed Tailwind CSS</p>
          </div>
          {/*  <!-- End Simple Success Alert --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertSimpleWarning}
          componentName="AlertSimpleWarning"
          disableColorPicker
        >
          {/*  <!-- Component: Simple Warning Alert --> */}
          <div
            className="w-full px-4 py-3 text-sm border rounded border-wuiAmber-100 bg-wuiAmber-50 text-wuiAmber-500"
            role="alert"
          >
            <p>Warning! Tailwind CSS requires latest version of PostCSS</p>
          </div>
          {/*  <!-- End Simple Warning Alert --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertSimpleInfo}
          componentName="AlertSimpleInfo"
          disableColorPicker
        >
          {/*  <!-- Component: Simple Info Alert --> */}
          <div
            className="w-full px-4 py-3 text-sm border rounded border-wuiCyan-100 bg-wuiCyan-50 text-wuiCyan-500"
            role="alert"
          >
            <p>Tip! Install Tailwind CSS IntelliSense</p>
          </div>
          {/*  <!-- End Simple Info Alert --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertSimpleDanger}
          componentName="AlertSimpleDanger"
          disableColorPicker
        >
          {/*  <!-- Component: Simple Danger Alert --> */}
          <div
            className="w-full px-4 py-3 text-sm border rounded border-wuiPink-100 bg-wuiPink-50 text-wuiPink-500"
            role="alert"
          >
            <p>Danger! Look behind you</p>
          </div>
          {/*  <!-- End Simple Danger Alert --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertSimpleToast}
          componentName="AlertSimpleToast"
          disableColorPicker
        >
          {/*  <!-- Component: Simple Toast Alert --> */}
          <div
            className="w-full px-4 py-3 text-sm border rounded border-wuiSlate-900 bg-wuiSlate-700 text-wuiSlate-200"
            role="alert"
          >
            <p>Welcome! Wind UI greets you</p>
          </div>
          {/*  <!-- End Simple Toast Alert --> */}
        </CopyComponent>
      </div>
    </>
  )
}
