import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function AlertAllTitleTextPreview(props) {
  const {
    AlertTitleTextSuccess,
    AlertTitleTextInfo,
    AlertTitleTextWarning,
    AlertTitleTextDanger,
    AlertTitleTextToast,
  } = props

  return (
    <>
      <div className="flex flex-col max-w-3xl gap-10">
        <CopyComponent
          copyToClipboardCode={AlertTitleTextSuccess}
          componentName="AlertTitleTextSuccess"
          disableColorPicker
        >
          {/*  <!-- Component: Success Alert With Title and Text--> */}
          <div
            className="w-full px-4 py-3 text-sm border rounded border-wuiEmerald-100 bg-wuiEmerald-50 text-wuiEmerald-500"
            role="alert"
          >
            <h3 className="mb-2 font-semibold">
              All components are now published.
            </h3>
            <p>
              You successfully read this important alert message. Green often
              indicates something successful or positive.
            </p>
          </div>
          {/*  <!-- End Success Alert With Title and Text--> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertTitleTextWarning}
          componentName="AlertTitleTextWarning"
          disableColorPicker
        >
          {/*  <!-- Component: Warning Alert With Title and Text--> */}
          <div
            className="w-full px-4 py-3 text-sm border rounded border-wuiAmber-100 bg-wuiAmber-50 text-wuiAmber-500"
            role="alert"
          >
            <h3 className="mb-2 font-semibold">
              Some components are not published!
            </h3>
            <p>
              You successfully read this important alert message. Yellow often
              indicates a warning that might need attention.
            </p>
          </div>
          {/*  <!-- End Warning Alert With Title and Text--> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertTitleTextInfo}
          componentName="AlertTitleTextInfo"
          disableColorPicker
        >
          {/*  <!-- Component: Info Alert With Title and Text--> */}
          <div
            className="w-full px-4 py-3 text-sm border rounded border-wuiCyan-100 bg-wuiCyan-50 text-wuiCyan-500"
            role="alert"
          >
            <h3 className="mb-2 font-semibold">All components are now live.</h3>
            <p>
              You successfully read this important alert message. Blue often
              indicates a neutral informative change or action.
            </p>
          </div>
          {/*  <!-- End Info Alert With Title and Text--> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertTitleTextDanger}
          componentName="AlertTitleTextDanger"
          disableColorPicker
        >
          {/*  <!-- Component: Danger Alert With Title and Text--> */}
          <div
            className="w-full px-4 py-3 text-sm border rounded border-wuiPink-100 bg-wuiPink-50 text-wuiPink-500"
            role="alert"
          >
            <h3 className="mb-2 font-semibold">Uploading components failed!</h3>
            <p>
              You successfully read this important alert message. Red often
              indicates a dangerous or negative situation.
            </p>
          </div>
          {/*  <!-- End Danger Alert With Title and Text--> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AlertTitleTextToast}
          componentName="AlertTitleTextToast"
          disableColorPicker
        >
          {/* <!-- Component: Toast Alert With Title and Text--> */}
          <div
            className="w-full px-4 py-3 text-sm border rounded border-wuiSlate-900 bg-wuiSlate-700 text-wuiSlate-200"
            role="alert"
          >
            <h3 className="mb-2 font-semibold text-wuiSlate-50">
              Retrying connection in 5 sec
            </h3>
            <p>
              You successfully read this important alert message. Grey
              represents neutrality and balance.{" "}
            </p>
          </div>
          {/* <!-- End Toast Alert With Title and Text--> */}
        </CopyComponent>
      </div>
    </>
  )
}
